import { createStore } from 'vuex'

export default createStore({
    state: {
        userInfo: {}
    },
    mutations: {
        getUser(state, data) {
            state.userInfo = data
        },
        // 
        // getUserName(state, name) {
        //     state.userInfo.user = name
        // }
    },
    actions: {},
    modules: {}
})
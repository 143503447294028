export default {
    data() {
        return {
           
        };
    },
    // 计算是否有权限
    computed: {
        
    },
    methods: {
       
    },
    mounted() {},
    created() {},
};
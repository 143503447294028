<template>
  <div class="login">
    <!-- 登录界面 -->
    <div class="admin">
      <div class="title_info">
        <!-- <img src="../assets/img/logo.png" alt="" /> -->
         <div>众鑫推</div>
      </div>
      <div class="admin_info">
        <!-- 选项卡切换 -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="密码登录" name="first">
            <!-- 用户账号 -->
            <div class="input input_top1">
              <img src="../assets/img/user.png" alt="用户" />
              <input
                type="text"
                v-model="username"
                placeholder="请输入用户名"
                aria-placeholder="placeholder"
                @keyup.enter.native="lineFeed"
              />
            </div>
            <!-- 用户密码 -->
            <div class="input input_top">
              <img src="../assets/img/password.png" alt="密码" />
              <input
                ref="password"
                v-model="userword"
                type="password"
                placeholder="请输入密码"
                aria-placeholder="placeholder"
                @keyup.enter.native="getuseradmin"
              />
            </div>
            <!-- 登录 -->
            <div class="Submit" @click="getlogin">登录</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 登录失败弹框 -->
    <div class="open" v-if="modifypassword">
      <div class="back" @click="modifypassword = false"></div>
      <div class="open_info">
        <img src="../assets/img/det_open.png" alt="" class="open_back_img" />
        <!-- 关闭 -->
        <img
          src="../assets/img/gb.png"
          alt=""
          class="gb"
          @click="modifypassword = false"
        />
        <div class="open_title">
          <!-- <img src="../assets/img/open_title.png" alt="" class="img_back" /> -->
          操作提示
        </div>
        <div class="open_input">
          {{ message }}
        </div>
        <!-- 取消 确定 -->
        <div class="operation operation_det">
          <div class="submit" @click="modifypassword = false">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      //   是否记住密码
      ifpassword: false, //默认为不勾选
      //   是否自动登录
      iflogin: false, //默认为不勾选
      username: "",
      userword: "",
      admin_user_id: "",
      modifypassword: false,
      message: "",
      zhandianopen: false,
      openchoicerole: false,
    };
  },
  mounted(){
    this.getlogin()
  },
  //
  methods: {
    // 记录位置 键盘事件
    lineFeed() {
      // 判断密码是否有密码
      if (this.userword.length > 0) {
        return this.getlogin();
      }
      this.$refs.password.focus();
    },
    getuseradmin() {
      this.getlogin();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 登录
    async getlogin() {
      // 判断是否填写用户名
      // if (this.username.length == 0) {
      //   return this.$message.error("请输入手机号");
      // }
      // // 判断是否填写密码
      // if (this.userword.length == 0) {
      //   return this.$message.error("请输入密码");
      // }
      let { data } = await this.$axios({
        url: this.$Api.logon,
        method: "post",
        header: {
          "Content-Type": " multipart/form-data",
        },
        data: {
          account: 'root',
          password: 'root123',
        },
      });
      // console.log(data);
      if (data.code != 1) {
        this.message = data.msg;
        return (this.modifypassword = true);
      }
      localStorage.setItem("info", JSON.stringify(data.data));
      this.$router.push({ path: "/Home" });
    },
    getzhandiantype2(type) {
      this.zhandianopen = type;
    },
  },
};
</script>
<style lang="less" scoped>
// login 全屏背景
.login {
  width: 100%;
  height: 100vh;
  background: url("../assets/img/login_back.png") no-repeat center;
  background-size: cover;
  position: relative;
  .admin {
    position: absolute;
    // width: 732px;
    width: 732px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    .title_info {
      width: 700px;
      // height: 144px;
      margin: 0 auto;
      // border: 1px dashed #b0b0b0;
      img {
        width: 100%;
        height: 100%;
      }
      div{
        font-family: "SourceHanSansCN-Bold ";
        font-weight: 400;
        font-size: 52px;
        letter-spacing: 0.1em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #01fff1;
        height: 100%;
        // position: absolute;
        z-index: 2;
        width: 100%;
      }
    }
    .admin_info {
      margin-top: 38px;
      width: 100%;
      height: 587px;
      background: url("../assets/img/admin_login.png") no-repeat center;
      background-size: 100%;
      // border: 1px dashed #b0b0b0;
      padding: 62px 53px;
      box-sizing: border-box;
      .input {
        width: 620px;
        height: 58px;
        border-radius: 6px;
        background: #013949;
        border: 2px solid #01fff1;
        padding: 0 25px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        input {
          width: 100%;
          height: 100%;
          padding-left: 10px;
          box-sizing: border-box;
          font-family: "MicrosoftYaHei ";
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          text-align: left;
          color: #01fff1;
          background: #013949;
        }
        input::-webkit-input-placeholder {
          font-family: "MicrosoftYaHei ";
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          text-align: left;
          color: #01fff1;
        }
      }
      .input_top {
        margin-top: 42px;
      }
      .input_top1 {
        margin-top: 20px;
      }

      .choice {
        width: 100%;
        .txt {
          font-family: "AlibabaPuHuiTi-Regular ";
          font-weight: 400;
          font-size: 18px;
          letter-spacing: 0.5px;
          line-height: 27px;
          text-align: left;
          margin-left: 10px;
          color: #dfffff;
          width: 100px;
        }
      }
      .choice_left {
        margin-left: 100px;
      }
      .flex {
        display: flex;
      }
      .Submit {
        width: 100%;
        height: 60px;
        border-radius: 6px;
        background: #00f8fa;
        font-family: "Microsoft YaHei Bold";
        font-weight: bold;
        font-size: 18px;
        color: #011f34;
        text-align: center;
        line-height: 60px;
        margin-top: 60px;
        user-select: none;
        cursor: pointer;
      }
    }
  }
}
.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    background: rgba(0, 0, 0, 0.3);
  }
  .open_info_site {
    width: 604px !important;
    height: 586px !important;
  }
  .open_info {
    position: absolute;
    width: 322px;
    height: 202px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    // background: url("../assets/img/open_back.png") no-repeat center;
    // background-size: 100%;
    padding: 25px;
    box-sizing: border-box;
    z-index: 7;
    .open_back_img {
      width: 100%;
      height: 100%;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .gb {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 30px;
      height: 30px;
      z-index: 9;
    }
    .gbs {
      position: absolute;
      right: 18px;
      top: 18px;
      width: 30px;
      height: 30px;
      z-index: 9;
    }
    .site_title {
      width: 100%;
      height: 51px;
      left: 0;
      // transform: translateX(10%);
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .site_name {
        position: absolute;
        top: 0;
        left: 0;
        height: 51px;
        width: 399px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 6;
        display: flex;
        justify-content: center;
        line-height: 51px;
        color: #01fff1;
        font-size: 24px;
      }
      .img_back {
        position: absolute;
        top: 0;
        left: 0;
        height: 51px;
        width: 399px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
      }
    }
    .open_title {
      width: 100%;
      // background: url("../assets/img/open_title.png") no-repeat center;
      height: 30px;
      text-align: center;
      font-family: "Source Han Sans CN Bold";
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.1em;
      color: #01fff1;
      position: relative;
      .img_back {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
    .open_input {
      margin: 10px auto;
      font-size: 18px;
      display: flex;
      // align-items: center;
      // justify-content: center;
      color: #ccffff;
      // height: 150px;
      position: relative;
      z-index: 6;
      .input {
        margin-top: 1.26vw;
        position: relative;
        .input_name {
          font-family: "Source Han Sans CN";
          font-weight: 400;
          font-size: 0.8vw;
          text-align: left;
          color: #01fff1;
        }
        .inputtextarea {
          width: 22.67vw !important;
          height: 4.02vw !important;
        }
        .input_input {
          margin-top: 15px;
          width: 17.67vw;
          height: 2.02vw;
          background: #043248;
          border: 1px solid #01fff1;
          border-radius: 6px;
          input {
            width: 100%;
            height: 100%;
            padding-left: 15px;
            box-sizing: border-box;
            font-family: "Source Han Sans CN";
            font-weight: 400;
            font-size: 0.7vw;
            color: #03999d;
          }
          input::placeholder {
            font-family: "Source Han Sans CN";
            font-weight: 400;
            font-size: 0.7vw;
            color: #03999d;
          }
        }
        .input_choice {
          position: relative;
        }
        .input_choice::after {
          content: "";
          position: absolute;
          right: 20px;
          top: 15px;
          width: 0;
          height: 0;
          border: 0.4vw solid transparent;
          border-bottom-color: #00f0e5;
          transform: rotate(180deg);
        }
      }
      .drop_down {
        position: absolute;
        top: 4.04vw;
        right: 0;
        width: 17.67vw;
        min-height: 100%;
        max-height: 200px;
        background: #043248;
        overflow: auto;
        transition: all 1s;
        border: 1px solid #00eee3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 8;
        .item {
          height: 32px;
          display: flex;
          align-items: center;
          color: #00f0e5;
          padding: 0 20px;
          box-sizing: border-box;
        }
      }
      .drop_down::-webkit-scrollbar {
        display: none;
      }
    }
    .operation_det {
      margin: 50px auto 0 !important;
    }
    .operation {
      margin: 30px auto 0;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 6;
      .cancel {
        width: 80px;
        height: 40px;
        border-radius: 4px;
        background: transparent;
        border: 1px solid #01fff1;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 40px;
        color: #01fff1;
        cursor: pointer;
        user-select: none;
      }
      .submit {
        margin-left: 15px;
        width: 80px;
        height: 40px;
        border-radius: 4px;
        background: #055f68;
        border: 1px solid #01fff1;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 40px;
        color: #01fff1;
        cursor: pointer;
        user-select: none;
      }
    }
    .operation_index {
      margin: 0 auto 0;
      border: 1px solid #01fff1;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 6;
      height: 60px;
      align-items: center;
      .cancel {
        width: 80px;
        height: 40px;
        border-radius: 4px;
        background: transparent;
        border: 1px solid #01fff1;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 40px;
        color: #01fff1;
        cursor: pointer;
        user-select: none;
      }
      .submit {
        margin-left: 15px;
        width: 80px;
        height: 40px;
        border-radius: 4px;
        background: #055f68;
        border: 1px solid #01fff1;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-align: center;
        line-height: 40px;
        color: #01fff1;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
.zhandianopen_height {
  height: calc(100% - 120px) !important;
  align-items: flex-start !important;
  // overflow: auto;
}
.zhandian {
  // display: flex;
  // align-items: center;
  width: 100%;
  height: 100%;
  // justify-content: center;
  // padding: 0 20px;
  // padding-top: 30px;
  box-sizing: border-box;
  border: 1px solid #00eee3;
  &::-webkit-scrollbar {
    display: none;
  }
  .zhandian_name {
    font-family: "Source Han Sans CN";
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #01fff1;
    height: 60px;
    width: 100%;
    margin-right: 20px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    border: 1px solid #00eee3;
    background: #022a3e;
    div {
      flex: 1;
      color: #01fff1;
      text-align: center;
      height: 100%;
      line-height: 60px;
      border-right: 1px solid #00eee3;
      cursor: pointer;
      user-select: none;
      &:hover {
        background: #013949;
      }
    }
  }
  .sty {
    position: static;
    top: 0;
  }
  .zhandian_flex {
    width: 100%;
    overflow: auto;
    margin-top: 20px;
    padding: 0 20px;
    height: calc(100% - 100px);
    box-sizing: border-box;
    &::-webkit-scrollbar {
      display: none;
    }
    .xuhao {
      display: flex;
      align-items: center;
      // width: 100px;
      // justify-content: space-between;
      // height: 100%;
      .Selectall {
        width: 22px;
        height: 22px;
        background: #055f68;
        border: 2px solid #01fff1;
      }
      .Selectallafter {
        position: relative;
      }
      .Selectallafter::after {
        content: "√";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #01fff1;
      }
      .text {
        font-family: "SourceHanSansCN-Regular ";
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: #01fff1;
      }
    }
  }
}
// 1560px
// 样式穿透
/deep/ .el-tabs__item {
  color: #dfffff;
  font-size: 18px;
  height: 50px;
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__item.is-active {
  color: #01fff1;
  font-size: 22px;
}
/deep/ .el-tabs__active-bar {
  background: #01fff1;
  width: 88px;
  margin-top: 14px;
}
/deep/ .el-tabs__item:hover {
  color: #01fff1;
}
/deep/ .el-tabs__nav-scroll {
  display: flex;
  // justify-content: center;
  margin-top: 50px;
}
</style>
<template>
  <!-- 头部导航 -->
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  computed: {},
};
</script>
<style lang="less">
@import url("./assets/css/qcmr.css");
/*自定义滚动条*/
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  // background: rgba(0,167,232,.3);
  background: #ededed;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.el-date-table td.today span {
  // background: #01fff1 !important;
  color: #cff !important;
}
.el-popper.is-light{
  background: #043248 !important;
  border: 1px solid #01fff1 !important;
}
.el-autocomplete-suggestion li{
   color: #03999d !important;
}
.el-picker-panel__body {
  background: #022a3e;
  border: 1px solid #01fff1;
}
.el-picker__popper.el-popper[role="tooltip"] {
  background: #022a3e !important;
  border: 1px solid #01fff1;
}
.el-autocomplete__popper.el-popper[role=tooltip] .el-popper__arrow::before{
  border: 1px solid #01fff1 !important;
  background:#03999d  !important;
}
.el-autocomplete-suggestion li:hover{
  background: #022a3e !important;
}
.el-date-table td span {
  color: #cff;
}
.el-date-picker__header-label {
  color: #01fff1 !important;
}
.el-date-table th {
  color: #01fff1 !important;
  border-bottom: none !important;
}
.el-date-table td.current:not(.disabled) span {
  background: #01fff1 !important;
  color: #000 !important;
}
.el-picker-panel__icon-btn {
  color: #01fff1 !important;
  font-size: 16px !important;
}
.el-input__inner{
  border: 1px solid #00f0e5 !important;
  background: #022a3e !important;
  color: #01fff1 !important;
}
.el-picker-panel__footer{
  color: #00f0e5 !important;
  border-top:1px solid #00f0e5 !important;
  background: #022a3e !important;
  // display: none;
  height: 52px;
  line-height: 52px;
  .el-button--text{
    color: #01fff1 !important;
  }
   .el-button span{
    color: #01fff1 !important;
    width: 58px;
    display: block;
  }
  .el-button--mini{
     background: #022a3e !important;
     border:1px solid #00f0e5 !important;
  }
}
.el-date-picker__time-header{
  border-bottom:1px solid #00f0e5 !important;
}
.el-popper.is-light .el-popper__arrow::before{
  background: #00f0e5 !important;
  border:1px solid #00f0e5 !important;
}
.el-time-panel{
  background: #022a3e !important;
  border:1px solid #00f0e5 !important;
  .el-time-spinner__item{
    color: #00f0e5 !important;
  }
  .el-time-panel__footer{
    border-top:1px solid #00f0e5 !important;
  }
  .el-time-panel__btn{
    color: #00f0e5 !important;
  }
  .el-time-spinner__item:hover:not(.disabled):not(.active){
    background: #022a3e !important;
  }
}
.el-time-panel__content::after, .el-time-panel__content::before{
  border-top:1px solid #00f0e5 !important;
  border-bottom:1px solid #00f0e5 !important;
}
.show_list {
  color: #ffffff;
  text-align: center;
  font-size: 32px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
}
.find_open{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  .find_back{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .find_info{
    position: absolute;
    width: 300px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border:1px solid #00f0e5 !important;
    font-size: 22px;
    background: #022a3f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #00f0e5 ;
  }
}
#app{
  height: 100vh;
  overflow: hidden;
}
</style>
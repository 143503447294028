import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import Api from "./assets/js/api.js"
import watermark from './assets/js/watermark.js'
import axios from "axios"
import mixins from './mixin/mixin.js'
// import vue3JsonExcel from "vue3-json-excel"
// import 'lib-flexible/flexible.js'
// import CountTo from 'vue-count-to';
import 'lib-flexible'
const app = createApp(App)
installElementPlus(app)
//判断真假
let ifdev = process.env.NODE_ENV === "development"
// 项目打包
app.config.globalProperties.$watermark = watermark
app.config.globalProperties.$Api = Api
const baseURL = ifdev ? Api.ipi : Api.ipi
axios.defaults.baseURL = baseURL
// axios.defaults.withCredentials = true //让axios携带cookie
app.config.globalProperties.$axios = axios
app.mixin(mixins)
// app.use(vue3JsonExcel)
// app.use(CountTo)
// import directive from "./assets/js/count.js";
// // 注册名为 change 的指令
app.directive('change', {
    inserted: function (el, binding, vnode) {
        console.log('22')
        el.addEventListener('change', () => {
            console.log('22')
            binding.value(); // 调用绑定的方法
        });
    }
});

app.use(store).use(router).mixin({
    data() {
        return {
            baseURL,
        }
    }
}).mount('#app')
<template>
    <div class="swich">
      <div class="">
        <div class="list_item" v-for="(item, index) in list" :key="index">
          <router-link :to="item.path">
            <!-- 背景图 -->
            <img
              src="../assets/img/swich_no.png"
              alt=""
              class="list_info_img"
              v-if="ifswich != index"
            />
            <img
              src="../assets/img/swich.png"
              v-else
              alt=""
              class="list_info_img"
            />
            <!-- 给定位 -->
            <div class="info" :class="ifswich == index ? 'info_after' : ''">
              <img v-if="ifswich != index" :src="item.icon" alt="" />
              <img v-else :src="item.icons" alt="" />
              <div :class="ifswich != index ? 'name' : 'names'">
                {{ item.name }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        list: [
          {
            name: "实时预警",
            path: "/",
            icon: require("../assets/img/swich_img.png"),
            icons: require("../assets/img/swich_imgs.png"),
          },
          {
            name: "历史告警",
            path: "/Historicalalarm",
            icon: require("../assets/img/swich_img.png"),
            icons: require("../assets/img/swich_imgs.png"),
          },
          {
            name: "系统设置",
            path: "/System",
            icon: require("../assets/img/shezhi.png"),
            icons: require("../assets/img/sys.png"),
          },
        ],
      };
    },
    props: {
      ifswich: {
        type: Number,
        default: 0,
      },
    },
  };
  </script>
  <style lang="less" scoped>
  a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .swich {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 7;
    .list_item {
      // border: 1px dashed #b0b0b0;
      width: 38px;
      margin-bottom: 30px;
      position: relative;
      height: 160px;
      .list_info_img {
        width: 100%;
        height: 100%;
      }
      .info_after {
        &::after {
          position: absolute;
          content: "";
          right: -36px;
          top: 0;
          width: 0;
          height: 0;
          border-top: 80px solid transparent;
          border-left: 28px solid #01fff1;
          border-bottom: 80px solid transparent;
          transform: rotate(-180deg);
        }
      }
      .info {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        img {
          width: 18px;
          height: 18px;
          // border: 1px dashed #b0b0b0;
        }
        .name {
          margin-top: 8px;
          font-family: "Microsoft YaHei";
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.1em;
          text-align: left;
          color: #027f8d;
          writing-mode: tb-rl;
        }
        .names {
          margin-top: 8px;
          font-family: "Microsoft YaHei Bold";
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.1em;
          text-align: left;
          color: #01fff1;
          writing-mode: tb-rl;
        }
      }
    }
  }
  </style>
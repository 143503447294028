<template>
    <!-- 导航头 -->
    <div class="nav">
      <div class="flex item_centent">
        <!-- 时间 -->
        <div class="date flex">
          <img src="../assets/img/date.png" alt="" />
          <div class="time">
            <span class="span1">{{ date.time + ":" }}</span>
            <span class="span1"> {{ date.branch + ":" }}</span>
            <span class="span2"> {{ date.second }}</span>
          </div>
          <div class="times">
            <div>{{ date.week }}</div>
            <div>{{ date.year + "." }}{{ date.month + "." }}{{ date.day }}</div>
          </div>
        </div>
        <!-- logo -->
        <div class="logo">
          <img class="logo_img" src="../assets/img/title.png" alt="" />
          <div class="">鑫推富</div>
        </div>
        <!-- 信息 -->
        <div class="user flex">
          <div class="txt">{{ siteuserlist.nickname || "" }}</div>
          <div class="span"></div>
          <div class="txt site_name">{{ siteuserlist.username }}</div>
          <img src="../assets/img/userinfo.png" alt="用户头像" />
          <!-- 下拉框 -->
          <div class="drop_down">
            <div
              class="item"
              v-for="(item, index) in list"
              :key="index"
              @click="getjournaldown(item)"
              :class="index != list.length - 1 ? 'drop_border' : ''"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 修改密码弹框 -->
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        date: {
          year: "",
          month: "",
          day: "",
          time: "",
          branch: "",
          second: "",
          week: "",
        },
        edituserinfo: false,
        modifypassword: false,
        list: [
           {
            name:'退出登录'
           }
        ],
        passwordlist: {
          oldpassword: "",
          newpassword: "",
          newpasswords: "",
        },
        siteuserlist: "",
        nickname: "",
        // 个人中心
        userinfo: {
          account: "",
          nickname: "",
          mobile: "",
          mailbox: "",
          remarks: "",
          startdate: "",
          frequency: "",
          xiaoxi: "",
        },
      };
    },
    props: {},
    computed: {},
    methods: {
      // 获取当前的时间
      getdate() {
        //+ "-"
        let date = new Date();
        let Y = date.getFullYear();
        let M =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let D =
          date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let m =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        var weekday = new Array(7);
        weekday[0] = "星期天";
        weekday[1] = "星期一";
        weekday[2] = "星期二";
        weekday[3] = "星期三";
        weekday[4] = "星期四";
        weekday[5] = "星期五";
        weekday[6] = "星期六";
        let eekday = weekday[date.getDay()];
        //
        this.date.year = Y;
        this.date.month = M;
        this.date.day = D;
        this.date.time = h;
        this.date.branch = m;
        this.date.second = s;
        this.date.week = eekday;
      },
      getxiaoxidown(item) {
        this.userinfo.xiaoxi = item.name;
        this.xiaoxidown = false;
      },
      //
      getjournaldown(item) {
        this.getcancellogin();
      },
      getuserinfo() {
        if (!this.siteuserlist) {
          let info = JSON.parse(localStorage.getItem("info"));
          // console.log(info)
          this.siteuserlist = info;
        }
      },
      // 退出登录
      getcancellogin() {
        localStorage.removeItem("info");
        this.$router.push({ path: "/login" });
      },
    },
    watch: {},
    created() {
      this.getuserinfo();
      setInterval(() => {
        this.getdate();
      }, 1000);
    },
    mounted() {},
  };
  </script>
  <style lang="less" scoped>
  // /deep/ .el-dialog{
  //   background: #043248 !important;
  // }
  // 日志弹框
  .txt1 {
    margin-right: 40px;
  }
  .dialogcontent {
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
    height: 40px;
    align-items: center;
    .name {
      font-size: 18px;
      // color: #01fff1;
    }
    input {
      height: 100%;
      width: 60%;
      padding-left: 10px;
      box-sizing: border-box;
      border-radius: 4px;
      // background: #022a3f;
      // border: 1px solid #00f0e5;
      border: 1px solid #b0b0b0;
      color: #000;
      font-size: 16px;
    }
    input::placeholder {
      font-size: 16px;
      color: #000;
      // color: #00f0e5;
    }
  }
  .dialogflex {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  /deep/ .dialogbutton {
    margin-left: 30px !important;
    color: #fff !important;
    span {
      color: #fff;
    }
  }
  .drop_down {
    position: absolute;
    top: 56px;
    right: 0;
    width: 120px;
    background: #043248;
    overflow: auto;
    transition: all 1s;
    border: 1px solid #00eee3;
    display: none;
    // flex-direction: column;
    // justify-content: center;
    z-index: 8;
    .item {
      height: 36px;
      display: flex;
      align-items: center;
      color: #00f0e5;
      padding: 0 20px;
      justify-content: center;
      box-sizing: border-box;
      // margin-top: 4px;
      cursor: pointer;
      user-select: none;
    }
    & .item:hover {
      background: #006f7b;
    }
    .drop_border {
      border-bottom: 1px solid #00eee3;
    }
  }
  .drop_down::-webkit-scrollbar {
    display: none;
  }
  .nav {
    width: 100%;
    // min-width: 1920px;
    padding: 0 30px 0 50px;
    box-sizing: border-box;
    height: 78px;
    position: relative;
    z-index: 699;
    .flex {
      display: flex;
    }
    .logo {
      width: 1226px;
      height: 56px;
      position: relative;
      // background: url("../assets/img/title.png") center no-repeat;
      // background-size: 100%;
      // border: 1px dashed #b0b0b0;
      .logo_img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      div {
        font-family: "SourceHanSansCN-Bold ";
        font-weight: 400;
        font-size: 30px;
        letter-spacing: 0.1em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #01fff1;
        height: 100%;
        position: absolute;
        z-index: 2;
        width: 100%;
      }
    }
    .user {
      align-items: center;
      position: relative;
      height: 56px;
      width: 500px;
      .txt {
        font-family: "Microsoft YaHei";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.1em;
        text-align: left;
        color: #01fff1;
        margin: 0 14px;
      }
      .site_name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 120px;
      }
      .span {
        height: 22px;
        width: 2px;
        background: #01fff1;
      }
      img {
        width: 36px;
        height: 36px;
        // border: 1px dashed #b0b0b0;
      }
      &:hover .drop_down {
        display: block;
      }
    }
    .item_centent {
      align-items: center;
      justify-content: space-between;
    }
    .date {
      align-items: center;
      // width: 289px;
      img {
        width: 36px;
        height: 36px;
        // border: 1px dashed #b0b0b0;
        position: relative;
        top: 4px;
      }
      .time {
        margin-left: 4px;
        letter-spacing: 1px;
        position: relative;
        top: 5px;
        .span1 {
          font-family: "Source Han Sans CN Bold";
          font-weight: bold;
          font-size: 36px;
          line-height: 14px;
          text-align: left;
          color: #01fff1;
        }
        .span2 {
          font-family: "Source Han Sans CN Bold";
          font-weight: bold;
          font-size: 26px;
          line-height: 14px;
          text-align: left;
          color: #01fff1;
        }
      }
      .times {
        position: relative;
        left: 20px;
        z-index: 2;
        div {
          font-family: "Source Han Sans CN Medium";
          font-weight: 500;
          font-size: 18px;
          letter-spacing: 0.05em;
          line-height: 28px;
          text-align: left;
          color: #01fff1;
        }
      }
    }
  }
  .open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    .back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 6;
      background: rgba(0, 0, 0, 0.3);
    }
    .open_info_width {
      width: 780px !important;
      height: 700px !important;
      .input_input {
        height: 48px !important;
      }
      .open_input {
        .input {
          .input_input {
            width: 300px !important;
          }
        }
      }
    }
    .operation_top {
      margin-top: 80px !important;
    }
    .open_info {
      position: absolute;
      width: 600px;
      height: 560px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: url("../assets/img/open_back.png") no-repeat center;
      background-size: 100%;
      padding: 25px;
      box-sizing: border-box;
      z-index: 7;
      .gb {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        z-index: 9;
      }
      .gb1 {
        position: absolute;
        right: -5px;
        top: 10px;
        width: 30px;
        height: 30px;
        z-index: 9;
      }
      .open_title {
        width: 100%;
        // background: url("../assets/img/open_title.png") no-repeat center;
        height: 40px;
        text-align: center;
        font-family: "Source Han Sans CN Bold";
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.1em;
        color: #01fff1;
        position: relative;
        .img_back {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      .open_input {
        margin: 2.02vw auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .input {
          margin-top: 1.26vw;
          position: relative;
          .input_name {
            font-family: "Source Han Sans CN";
            font-weight: 400;
            font-size: 0.8vw;
            text-align: left;
            color: #01fff1;
          }
          .inputtextarea {
            width: 22.67vw !important;
            height: 4.02vw !important;
          }
          .input_input {
            margin-top: 15px;
            width: 17.67vw;
            height: 2.02vw;
            background: #043248;
            border: 1px solid #01fff1;
            border-radius: 6px;
            input {
              width: 100%;
              height: 100%;
              padding-left: 15px;
              box-sizing: border-box;
              font-family: "Source Han Sans CN";
              font-weight: 400;
              font-size: 0.7vw;
              color: #03999d;
            }
            input::placeholder {
              font-family: "Source Han Sans CN";
              font-weight: 400;
              font-size: 0.7vw;
              color: #03999d;
            }
          }
          .input_choice {
            position: relative;
          }
          .input_choice::after {
            content: "";
            position: absolute;
            right: 20px;
            top: 15px;
            width: 0;
            height: 0;
            border: 0.4vw solid transparent;
            border-bottom-color: #00f0e5;
            transform: rotate(180deg);
          }
        }
        .drop_down {
          position: absolute;
          top: 4.04vw;
          right: 0;
          width: 17.67vw;
          min-height: 100%;
          max-height: 200px;
          background: #043248;
          overflow: auto;
          transition: all 1s;
          border: 1px solid #00eee3;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 8;
          .item {
            height: 32px;
            display: flex;
            align-items: center;
            color: #00f0e5;
            padding: 0 20px;
            box-sizing: border-box;
          }
          .item_border {
            border-bottom: 1px solid #00eee3;
          }
        }
        .drop_down::-webkit-scrollbar {
          display: none;
        }
      }
      .operation {
        margin: 3.03vw auto 0;
        display: flex;
        justify-content: center;
        .cancel {
          width: 80px;
          height: 40px;
          border-radius: 4px;
          background: transparent;
          border: 1px solid #01fff1;
          font-family: "Microsoft YaHei";
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.1em;
          text-align: center;
          line-height: 40px;
          color: #01fff1;
          cursor: pointer;
          user-select: none;
        }
        .submit {
          margin-left: 15px;
          width: 80px;
          height: 40px;
          border-radius: 4px;
          background: #055f68;
          border: 1px solid #01fff1;
          font-family: "Microsoft YaHei";
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.1em;
          text-align: center;
          line-height: 40px;
          color: #01fff1;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
  </style>
// 接口
let ipi = 'https://zhongxintui.cn' //http://goto.com/admin/ http://l.goto.com/  http://182.61.145.175/
    // 个人中心
    // 登录接口
let logon = '/api/login/adminlogin'
    // 设置信息接口
let info = '/api/screen/ScreenHome'
export default {
    ipi, //路径
    logon, //登录接口
    info, //信息
}
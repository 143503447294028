import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
import Home from '../views/Home.vue' //首页
import Login from '../views/Login.vue' //登录页面
//  404 页面
// import NotFound from "../views/NotFound.vue"
const routes = [
{
    path: '/',
    name: 'Login',
    component: Login
}, 
{
    path: '/Home',
    name: 'Home',
    component: Home
},
    // 404 页面
    // {
    //     path: '/:error(.*)*',
    //     name: 'NotFound',
    //     component: NotFound
    // },
]
const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})
export default router